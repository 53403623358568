var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-10"},[_c('v-toolbar',{staticClass:"mb-10",attrs:{"dense":"","flat":""}},[_c('div',{staticClass:"text-h4 primary--text"},[_vm._v("ตั้งค่า ค่าคอม พ.ร.บ. ประกันภัย")])]),_c('h3',{staticClass:"mb-2 error--text"},[_vm._v(" ค่าคอม พ.ร.บ. ประกัน คิดเป็น % จากราคาสุทธิ ")]),_c('v-data-table',{staticClass:"elevation-2 ",attrs:{"headers":_vm.headers,"items":_vm.list,"loading":_vm.loading,"sort-by":"","loading-text":_vm.$table_loading_text,"page":_vm.filter.page,"items-per-page":_vm.filter.item_per_page,"hide-default-footer":"","item-key":"_id"},on:{"update:page":function($event){return _vm.$set(_vm.filter, "page", $event)}},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.list .map(function(x) { return x.insurance_id; }) .indexOf(item.insurance_id) + 1)+" ")]}},{key:"item.third_insurance_1",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"py-2 ",attrs:{"hide-details":"","outlined":"","dense":"","onkeypress":"return event.charCode >= 48 && event.charCode <= 57","maxlength":"2","disabled":item.tag=='RVP'},model:{value:(item.third_insurance_1),callback:function ($$v) {_vm.$set(item, "third_insurance_1", _vm._n($$v))},expression:"item.third_insurance_1"}})]}},{key:"item.third_insurance_2",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"py-2 ",attrs:{"hide-details":"","outlined":"","dense":"","onkeypress":"return event.charCode >= 48 && event.charCode <= 57","maxlength":"2","disabled":item.tag=='RVP'},model:{value:(item.third_insurance_2),callback:function ($$v) {_vm.$set(item, "third_insurance_2", _vm._n($$v))},expression:"item.third_insurance_2"}})]}},{key:"item.third_insurance_3",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"py-2",attrs:{"hide-details":"","outlined":"","dense":"","onkeypress":"return event.charCode >= 48 && event.charCode <= 57","maxlength":"2","disabled":item.tag=='RVP'},model:{value:(item.third_insurance_3),callback:function ($$v) {_vm.$set(item, "third_insurance_3", _vm._n($$v))},expression:"item.third_insurance_3"}})]}},{key:"item.third_insurance_other",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"py-2",attrs:{"hide-details":"","outlined":"","dense":"","onkeypress":"return event.charCode >= 48 && event.charCode <= 57","maxlength":"2","disabled":item.tag=='RVP'},model:{value:(item.third_insurance_other),callback:function ($$v) {_vm.$set(item, "third_insurance_other", _vm._n($$v))},expression:"item.third_insurance_other"}})]}},{key:"item.insurance_0",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"py-2",attrs:{"hide-details":"","outlined":"","dense":"","onkeypress":"return event.charCode >= 48 && event.charCode <= 57","maxlength":"2","disabled":item.tag=='RVP'},model:{value:(item.insurance_0),callback:function ($$v) {_vm.$set(item, "insurance_0", _vm._n($$v))},expression:"item.insurance_0"}})]}},{key:"item.insurance_1",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"py-2 ",attrs:{"hide-details":"","outlined":"","dense":"","onkeypress":"return event.charCode >= 48 && event.charCode <= 57","maxlength":"2","disabled":item.tag=='RVP'},model:{value:(item.insurance_1),callback:function ($$v) {_vm.$set(item, "insurance_1", _vm._n($$v))},expression:"item.insurance_1"}})]}},{key:"item.insurance_2",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"py-2",attrs:{"hide-details":"","outlined":"","dense":"","onkeypress":"return event.charCode >= 48 && event.charCode <= 57","maxlength":"2","disabled":item.tag=='RVP'},model:{value:(item.insurance_2),callback:function ($$v) {_vm.$set(item, "insurance_2", _vm._n($$v))},expression:"item.insurance_2"}})]}},{key:"item.insurance_3",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"py-2",attrs:{"hide-details":"","outlined":"","dense":"","onkeypress":"return event.charCode >= 48 && event.charCode <= 57","maxlength":"2","disabled":item.tag=='RVP'},model:{value:(item.insurance_3),callback:function ($$v) {_vm.$set(item, "insurance_3", _vm._n($$v))},expression:"item.insurance_3"}})]}},{key:"item.insurance_4",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"py-2",attrs:{"hide-details":"","outlined":"","dense":"","onkeypress":"return event.charCode >= 48 && event.charCode <= 57","maxlength":"2","disabled":item.tag=='RVP'},model:{value:(item.insurance_4),callback:function ($$v) {_vm.$set(item, "insurance_4", _vm._n($$v))},expression:"item.insurance_4"}})]}},{key:"item.insurance_5",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"py-2",attrs:{"hide-details":"","outlined":"","dense":"","onkeypress":"return event.charCode >= 48 && event.charCode <= 57","maxlength":"2","disabled":item.tag=='RVP'},model:{value:(item.insurance_5),callback:function ($$v) {_vm.$set(item, "insurance_5", _vm._n($$v))},expression:"item.insurance_5"}})]}},{key:"item.insurance_6",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"py-2",attrs:{"hide-details":"","outlined":"","dense":"","onkeypress":"return event.charCode >= 48 && event.charCode <= 57","maxlength":"2","disabled":item.tag=='RVP'},model:{value:(item.insurance_6),callback:function ($$v) {_vm.$set(item, "insurance_6", _vm._n($$v))},expression:"item.insurance_6"}})]}},{key:"footer",fn:function(){return [_c('table-pagination-custom',{attrs:{"page":_vm.filter.page,"itemsPerPage":_vm.filter.item_per_page,"length":_vm.pageCount},on:{"change-page":_vm.onChangePage,"change-items-per-page":_vm.onChangeitemsPerPage}})]},proxy:true}],null,true)}),_c('v-row',[_c('v-col',{staticClass:"text-right py-10"},[_c('v-btn',{staticClass:"px-10 mr-15",attrs:{"color":"grey","outlined":""},on:{"click":function($event){return _vm.clear()}}},[_vm._v(" ปิด ")]),_c('v-btn',{staticClass:"px-10",attrs:{"color":"primary","loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){return _vm.save()}}},[_vm._v(" บันทึกข้อมูล ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }