<template>
  <div class="px-10">
    <v-toolbar dense flat class="mb-10">
      <div class="text-h4 primary--text">ตั้งค่า ค่าคอม พ.ร.บ. ประกันภัย</div>
    </v-toolbar>
    <h3 class="mb-2 error--text">
      ค่าคอม พ.ร.บ. ประกัน คิดเป็น % จากราคาสุทธิ
    </h3>
    <v-data-table
      class="elevation-2 "
      :headers="headers"
      :items="list"
      :loading="loading"
      sort-by=""
      :loading-text="$table_loading_text"
      :page.sync="filter.page"
      :items-per-page="filter.item_per_page"
      hide-default-footer
      item-key="_id"
    >
      <template v-slot:[`item.index`]="{ item }">
        {{
          list
            .map(function(x) {
              return x.insurance_id;
            })
            .indexOf(item.insurance_id) + 1
        }}
      </template>
      <template v-slot:[`item.third_insurance_1`]="{ item }">
        <v-text-field
          class="py-2 "
          hide-details
          outlined
          dense
          v-model.number="item.third_insurance_1"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          maxlength="2"
          :disabled="item.tag=='RVP'"
        />
      </template>
      <template v-slot:[`item.third_insurance_2`]="{ item }">
        <v-text-field
          class="py-2 "
          hide-details
          outlined
          dense
          v-model.number="item.third_insurance_2"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          maxlength="2"
          :disabled="item.tag=='RVP'"
        />
      </template>
      <template v-slot:[`item.third_insurance_3`]="{ item }">
        <v-text-field
          class="py-2"
          hide-details
          outlined
          dense
          v-model.number="item.third_insurance_3"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          maxlength="2"
          :disabled="item.tag=='RVP'"
        />
      </template>
      <template v-slot:[`item.third_insurance_other`]="{ item }">
        <v-text-field
          class="py-2"
          hide-details
          outlined
          dense
          v-model.number="item.third_insurance_other"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          maxlength="2"
          :disabled="item.tag=='RVP'"
        />
      </template>
      <template v-slot:[`item.insurance_0`]="{ item }">
        <v-text-field
          class="py-2"
          hide-details
          outlined
          dense
          v-model.number="item.insurance_0"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          maxlength="2"
          :disabled="item.tag=='RVP'"
        />
      </template>
      <template v-slot:[`item.insurance_1`]="{ item }">
        <v-text-field
          class="py-2 "
          hide-details
          outlined
          dense
          v-model.number="item.insurance_1"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          maxlength="2"
          :disabled="item.tag=='RVP'"
        />
      </template>

      <template v-slot:[`item.insurance_2`]="{ item }">
        <v-text-field
          class="py-2"
          hide-details
          outlined
          dense
          v-model.number="item.insurance_2"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          maxlength="2"
          :disabled="item.tag=='RVP'"
        />
      </template>

      <template v-slot:[`item.insurance_3`]="{ item }">
        <v-text-field
          class="py-2"
          hide-details
          outlined
          dense
          v-model.number="item.insurance_3"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          maxlength="2"
          :disabled="item.tag=='RVP'"
        />
      </template>

      <template v-slot:[`item.insurance_4`]="{ item }">
        <v-text-field
          class="py-2"
          hide-details
          outlined
          dense
          v-model.number="item.insurance_4"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          maxlength="2"
          :disabled="item.tag=='RVP'"
        />
      </template>

      <template v-slot:[`item.insurance_5`]="{ item }">
        <v-text-field
          class="py-2"
          hide-details
          outlined
          dense
          v-model.number="item.insurance_5"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          maxlength="2"
          :disabled="item.tag=='RVP'"
        />
      </template>
      <template v-slot:[`item.insurance_6`]="{ item }">
        <v-text-field
          class="py-2"
          hide-details
          outlined
          dense
          v-model.number="item.insurance_6"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          maxlength="2"
          :disabled="item.tag=='RVP'"
        />
      </template>
      <template v-slot:footer>
        <table-pagination-custom
          :page="filter.page"
          :itemsPerPage="filter.item_per_page"
          :length="pageCount"
          @change-page="onChangePage"
          @change-items-per-page="onChangeitemsPerPage"
        />
      </template>
    </v-data-table>
    <v-row>
      <v-col class="text-right py-10">
        <v-btn class="px-10 mr-15" color="grey" outlined @click="clear()">
          ปิด
        </v-btn>
        <v-btn
          class="px-10"
          color="primary"
          @click="save()"
          :loading="loading"
          :disabled="loading"
        >
          บันทึกข้อมูล
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    data: () => ({
      loading: true,
      pageCount: 0,
      filter: {
        item_per_page: 10,
        page: 1,
        start_with: ''
      },
      list: [],
      items: [],
      headers: [
        { text: '#', value: 'index', sortable: false },
        { text: 'บริษัท', value: 'name', sortable: false, width: '15%' },
        { text: 'นายหน้า', value: 'broker', sortable: false, width: '7%' },
        {
          text: 'พ.ร.บ. รย.1',
          value: 'third_insurance_1',
          sortable: false,
          align: 'center'
        },
        {
          text: 'พ.ร.บ. รย.2',
          value: 'third_insurance_2',
          sortable: false,
          align: 'center'
        },
        {
          text: 'พ.ร.บ. รย.3',
          value: 'third_insurance_3',
          sortable: false,
          align: 'center'
        },
        {
          text: 'พ.ร.บ. อื่นๆ',
          value: 'third_insurance_other',
          sortable: false,
          align: 'center'
        },
        { text: 'ป.1', value: 'insurance_0', align: 'center', sortable: false },
        { text: 'ป.2', value: 'insurance_1', align: 'center', sortable: false },
        { text: 'ป.2+', value: 'insurance_2', align: 'center', sortable: false },
        { text: 'ป.3', value: 'insurance_3', align: 'center', sortable: false },
        { text: 'ป.3+', value: 'insurance_4', align: 'center', sortable: false },
        { text: 'ป.4', value: 'insurance_5', align: 'center', sortable: false },
        { text: 'อื่นๆ', value: 'insurance_6', align: 'center', sortable: false }
      ]
    }),
    mounted() {
      this.filter.branch_id = this.$store.state.selected_branch._id;
      this.getData();
    },
    methods: {
      async getData() {
        this.loading = true;
        let body = {
          token: this.$jwt.sign(this.filter, this.$privateKey, {
            noTimestamp: true
          })
        };
        await this.$axios
          .post(`${this.$baseUrl}/insurance_commission/get_commission`, body)
          .then(res => {
            this.items = res.result;
            this.list = this.items.commission;
            this.filter.pageCount = res.result.total_page || 1;
            console.log(this.filter);
            console.log(this.list);
            if (this.list.length % this.item_per_page == 0) {
              this.pageCount =
                parseInt(this.list.length / this.filter.item_per_page) || 1;
            } else {
              this.pageCount =
                parseInt(this.list.length / this.filter.item_per_page) + 1 || 1;
            }
          })
          .catch(err => {
            console.log('err', err);
            this.$alertServerError({ title: err.error_message });
          });
        this.loading = false;
      },
      async save() {
        this.loading = true;
        this.$alertConfirm({ title: `ยืนยันบันทึกข้อมูล ?`, text: `` }).then(
          async result => {
            if (result.isConfirmed) {
              // delete this.items;
              let body = {
                token: this.$jwt.sign(this.items, this.$privateKey, {
                  noTimestamp: true
                })
              };
              await this.$axios
                .post(
                  `${this.$baseUrl}/insurance_commission/edit_commission`,
                  body
                )
                .then(res => {
                  this.$alertSuccess({ title: res.status });
                })
                .catch(err => {
                  console.log('err', err);
                  this.$alertServerError({ title: err.error_message });
                });
              console.log(this.items);
            }
            this.loading = false;
          }
        );

        this.loading = false;
      },
      clear() {
        this.getData();
      },
      onChangePage(page) {
        this.filter.page = page;
        this.getData();
      },
      onChangeitemsPerPage(number) {
        this.filter.item_per_page = number;
        this.onChangePage(1);
      }
    }
  };
</script>
